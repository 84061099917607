import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {setBaseEnvironment} from '../../../commons/actions/environment'
import {createUouBiMiddlewareWithBiParams} from '../../../commons/bi/bi'
import {createReduxStore} from '../../../commons/utils/store'
import {getExtraEventData} from '../Widget/actions/event'
import {fetchCurrentMember} from '../Widget/actions/members'
import {openSeatingPlan} from '../Widget/actions/modals'
import {handleInitialNavigation} from '../Widget/actions/navigation'
import {getMemberRsvp} from '../Widget/actions/rsvp'
import * as eventsUou from '../Widget/bi/uou-bi-events-map'
import {WarmupStateKey} from '../Widget/constants'
import {datesMiddleware} from '../Widget/middlewares/date'
import reducers from '../Widget/reducers'
import {isOrderPending, isOrderSuccess} from '../Widget/selectors/navigation'
import {createLogger} from '../Widget/services/logger'
import type {State} from '../Widget/types/state'
import {StoreExtraArgs} from '../Widget/types/store-extra-arg'
import {userEventsLogger} from '../Widget/user-events-logger'
import {Api} from '../Widget/utils/api'
import {getInitialData} from './initial-data'

export const getStore = async (controllerParams: ControllerParams) => {
  const {
    flowAPI,
    controllerConfig: {wixCodeApi},
  } = controllerParams
  const serverApi = new Api(controllerParams)
  const ssr = flowAPI.environment.isSSR

  const state: State = wixCodeApi.window.warmupData.get(WarmupStateKey) ?? undefined

  if (ssr || !state) {
    const initialData = await getInitialData(serverApi, controllerParams)

    const newStore = createStore(
      controllerParams,
      {
        ...initialData,
      },
      serverApi,
    )

    await newStore.dispatch(setBaseEnvironment() as any)
    try {
      await newStore.dispatch(fetchCurrentMember() as any)
    } catch (e) {
      console.error(e)
    }

    await Promise.all([
      newStore.dispatch(handleInitialNavigation() as any),
      newStore.dispatch(getMemberRsvp() as any),
      ...newStore.dispatch(getExtraEventData() as any),
      wixCodeApi.location.query.chooseSeat || wixCodeApi.location.query.chooseMultipleSeats
        ? newStore.dispatch(openSeatingPlan() as any)
        : null,
    ])
    const data = newStore.getState()
    if (flowAPI.experiments.enabled(ExperimentNames.UseWarmupState) && ssr) {
      wixCodeApi.window.warmupData.set(WarmupStateKey, data)
    }
    return newStore
  } else {
    const oldStore = createStore(controllerParams, state, serverApi)

    if (isOrderSuccess(state) || isOrderPending(state)) {
      oldStore.dispatch(handleInitialNavigation() as any)
    }

    return oldStore
  }
}

const createStore = (controllerParams: ControllerParams, initialData: Partial<State>, serverApi: Api) => {
  const {controllerConfig: controller, flowAPI} = controllerParams
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createUouBiMiddlewareWithBiParams(
    {
      wixCodeApi,
      flowAPI,
      platformAPIs,
      appParams,
      compId,
    },
    eventsUou,
  )

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState},
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      platformAPIs,
      flowAPI: controllerParams.flowAPI,
      logger: createLogger({
        fedops: controllerParams.flowAPI.fedops,
        panoramaClient: controllerParams.flowAPI.panoramaClient,
      }),
    },
    middleware: [biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}
